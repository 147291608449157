import React from 'react'
import '../style.css'
import FormRegister from './components/FormRegister'

const Register = () => {
  return (
    <section id='register'>
        <div className="container">
          {/* <h3>What can us do for you?</h3> */}
          {/* <p>We are ready to work on a project of any complexity,<br />whether it's commercial or residental.</p> */}
          <h2>What can us do for you?</h2>
          <p>We are ready to work on a project of any complexiity, <br />whether it's commercial or residental</p>
          <div className="form">
            <FormRegister />
          </div>
        </div>
    </section>
  )
}

export default Register