import React, { useState } from 'react'
import '../navbar/navbar.css'
import Headroom from 'react-headroom'
import Logo from '../../img/logo.png'

const Navbar = () => {
    const [clicked, setClicked] = useState(false)

    const handleClick = () => {
        setClicked(!clicked)
    }
    
    const handleClickMenu = () => {
        setClicked(!clicked)
    }
    
  return (
    <Headroom>
        <div className='nav'>
            <a href="/">
                <img src={Logo} alt="" />
            </a>
            <div>
                <ul className={`navbar ${clicked ? '' : 'active'}`}>
                    <li><a onClick={handleClickMenu} href='#header'>Home</a></li>
                    <li><a onClick={handleClickMenu} href='#about-us'>About Us</a></li>
                    <li><a onClick={handleClickMenu} href='#program'>Programs</a></li>
                    <li><a className='reg' onClick={handleClickMenu} href='#register'>Register Now</a></li>
                </ul>
            </div>
            <div className="hamburger" onClick={handleClick}>
                <i className={`fa-solid ${clicked ? 'fa-xmark' : 'fa-bars'}`}></i>
            </div>
        </div>
    </Headroom>
  )
}

export default Navbar