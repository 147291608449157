import '../style.css'
const WhySection = () => {
  return (
    <section id='why-section'>
        <div className="container">
            <h3 className='why-title'>About the Scholarship Program</h3>
            <div className="row center">
                <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="box-benefit">
                    <i className="fa-solid fa-user-graduate"></i>
                    <h1 className='title'>50%</h1>
                    <p>Awarded to the one top applicant</p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="box-benefit">
                    <i className="fa-solid fa-users"></i>
                    <h1 className='title'>30%</h1>
                    <p>Available for the next three applicants</p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="box-benefit">
                    <i className="fa-solid fa-user-tie"></i>
                    <h1 className='title'>20%</h1>
                    <p>Awarded to six quaified applicants</p>
                    </div>
                </div>
                {/* <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="box-benefit">
                    <i className="fa-solid fa-handshake-simple"></i>
                    <h5 className='title'>Collaboration</h5>
                    <p>Over 1000 Industry Partners</p>
                    </div>
                </div> */}
            </div>
        </div>
    </section>
  )
}

export default WhySection