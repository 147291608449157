import React from 'react'
import '../style.css'

const Header = () => {
  return (
  <>
    <section id='header'>
        <div className="hero">
          <div className="container blue">
            <h1>Your Pathway to Academic Excellence with Scholarships up to 50%!</h1>
            <h4>Ambassador Scholarship Program - Stenberg College, Canada</h4>
          </div>
        </div>
    </section>
  </>
  )
}

export default Header