import '../style.css'
const WhySection = () => {
  return (
    <section id='why-section'>
        <div className="container">
            <h3 className='why-title'>About the Scholarship Program</h3>
            <div className="row center">
                <div className="col-sm-12 col-md-6 col-lg-2">
                    <div className='box-program2'>
                        <i className="fa-solid fa-book"></i>
                        <h5 className='title'>Education</h5>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-2">
                    <div className='box-program2'>
                        <i className="fa-solid fa-calendar-alt"></i>
                        <h5 className='title'>Event Management</h5>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-2">
                    <div className='box-program2'>
                        <i className="fa-solid fa-globe"></i>
                        <h5 className='title'>International Tourism</h5>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-2">
                    <div className='box-program2'>
                        <i className="fa-solid fa-business-time"></i>
                        <h5 className='title'>Business</h5>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-2">
                    <div className='box-program2'>
                        <i className="fa-solid fa-chart-pie"></i>
                        <h5 className='title'>Marketing</h5>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
  )
}

export default WhySection