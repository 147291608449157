import React from 'react'
import '../style.css'
import Stenberg from '../../src/img/stenberg.png'
import Fade from 'react-reveal/Fade';

const AboutUs = () => {
  return (
    <section id='about-us'>
        <img src={Stenberg} alt="" />
        <div className="about-us">
            <h5 className='title'>About Us</h5>
            <Fade>
                <p>In 2023, Stenberg College was acquired by Canadian College, an educational institution that focuses on providing international students with diploma and certification programs in Business, Hospitality, International Trade and Information Technology.</p>
            </Fade>
            <button className='btn'>More On Our Histoty</button>
        </div>  
    </section>
  )
}

export default AboutUs